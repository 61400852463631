var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "MembersHeader",
        {
          attrs: { ticket: _vm.ticket, "show-responses": _vm.showResponses },
          on: { hideTicketResponses: _vm.hideTicketResponses },
        },
        [_vm._v(">")]
      ),
      _vm.showResponses
        ? _c("MembersResponseProgress", { attrs: { ticket: _vm.ticket } })
        : _c("MemberNoResponseRequiredVue", { attrs: { ticket: _vm.ticket } }),
      _c("Members", {
        attrs: { ticket: _vm.ticket, "show-responses": _vm.showResponses },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }