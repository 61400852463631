<template>
  <v-card>
    <MembersHeader
      :ticket="ticket"
      :show-responses="showResponses"
      @hideTicketResponses="hideTicketResponses"
      >></MembersHeader
    >
    <MembersResponseProgress
      v-if="showResponses"
      :ticket="ticket"
    ></MembersResponseProgress>
    <MemberNoResponseRequiredVue
      :ticket="ticket"
      v-else
    ></MemberNoResponseRequiredVue>
    <Members :ticket="ticket" :show-responses="showResponses"></Members>
  </v-card>
</template>
<script>
export default {
  components: {
    MembersHeader: () => import("@/components/members/MembersHeader.vue"),
    MembersResponseProgress: () =>
      import("@/components/members/MembersResponseProgress"),
    MemberNoResponseRequiredVue: () =>
      import("@/components/members/MemberNoResponseRequired"),
    Members: () => import("@/components/members/Members.vue"),
  },
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
    showResponses: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    hideTicketResponses() {
      this.$emit("hideTicketResponses");
    },
  },
};
</script>
<style></style>
